<template>
    <BasicLayout>
        <AnoneSearchArea/>
        <ContentSection bgc="#fff">
            <ItemContainer class="container" :itemList="items"></ItemContainer>
        </ContentSection>
    </BasicLayout>
</template>

<script>
    import BasicLayout from "@/layouts/BasicLayout.vue";
    import AnoneSearchArea from "@/components/AnoneSearchArea.vue";
    import ContentSection from "@/components/ContentSection.vue";
    import ItemContainer from "@/components/ItemContainer.vue";
    import items from "@/Items";
    export default {
        components: {
            BasicLayout,
            AnoneSearchArea,
            ItemContainer,
            ContentSection,
        },
        data: function() {
            return {
                items: items
            }
        }
    };

</script>

<style scoped>
    /* 強引に寄せてる スマホの時だけでいい */
    .container{
        margin-top: -80px;
    }
</style>