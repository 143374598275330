<template>
    <div class="title"><slot></slot></div>
</template>

<script>
</script>

<style scoped>
    .title{
        position: relative;
        font-weight: 600;
        margin: 0 auto;
        width: fit-content;
        z-index: 1;
        font-size: 1.2rem;
        margin-bottom: 32px;
    }
    .title::before{
        content: "";
        position: absolute;
        top: 0;
        width: 110%;
        height: 80%;
        background-color: #BDA6CF;
        transform: translateY(40%) translateX(-5%);
        z-index: -1;
    }
</style>