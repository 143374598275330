<template>
    <header class="header" id="header">
        <div class="menu_list">
            <router-link to="/">
                <div class="icon">
                    <HomeIcon :size="32"/>
                    <span class="icon_name">ホーム</span>
                </div>
            </router-link>
            <div class="icon">
                <HeeartIcon :size="32"/>
                <span class="icon_name">オキニ</span>
            </div>
            <router-link to="/cart">
                <div class="icon">
                    <ShoppingIcon :size="32"/>
                    <span class="icon_name">バッグ</span>
                </div>
            </router-link>
            <router-link to="/ItemList">
                <div class="icon">
                    <MagnifyIcon :size="32"/>
                    <span class="icon_name">検索</span>
                </div>
            </router-link>
            <div class="icon" v-on:click="open">
                <MenuIcon :size="32"/>
                <span class="icon_name">メニュー</span>
            </div>
        </div>
    </header>
    <AnoneMenu :isOpen="isMenuOpen" :closeFnc="close"></AnoneMenu>
</template>

<script>
    window.onload = ()=>{
        const h = document.getElementById("header").clientHeight;
        const dummy = document.createElement("div");
        dummy.classList.add("dummy");
        dummy.setAttribute("style",`height: ${h}px`);
        document.querySelector("body").appendChild(dummy);
    }
    import MenuIcon from 'vue-material-design-icons/Menu.vue';
    import HomeIcon from 'vue-material-design-icons/Home.vue';
    import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
    import ShoppingIcon from 'vue-material-design-icons/Shopping.vue';
    import HeeartIcon from 'vue-material-design-icons/Heart.vue';
    import AnoneMenu from './AnoneMenu.vue';
    export default {
        components: {
            MenuIcon,
            HomeIcon,
            MagnifyIcon,
            ShoppingIcon,
            HeeartIcon,
            AnoneMenu
        },
        // 状態
        data() {
            return {
                isMenuOpen: false
            }
        },
        // アクション
        methods: {
            open(){
                this.isMenuOpen = true
            },
            close(){
                this.isMenuOpen = false
            }
        }
    }
</script>

<style scoped>
    .header{
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #BDA6CF;
        display: flex;
        align-items: center;
        padding: 0 12px ;
        color: #fff;
        width: 100%;
        z-index: 100;
        box-shadow: 0px -2px 4px #a577c99c;
    }
    .menu_list{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        padding-bottom: 24px;
    }
    .icon{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .icon_name{
        font-size: 12px;
        font-weight: lighter;
    }
    .dummy{
        width: 100%;
    }
</style>