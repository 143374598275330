<template>
    <section class="cart_item_container" v-for="item in itemList" :key="item" :item="item">
            <div class="item">
                <router-link :to="/detail/ + item.id"><img class="item_image" :src="require('../assets/items/' + item.image)" alt=""></router-link>
                <div id="item_data">
                    <p class="product_name">{{ item.name }}</p>
                    <p class="product_price">¥{{ item.price.toLocaleString() }}</p>
                </div>
            </div>
    </section>
</template>

<script>
export default {
    components: {
},
    props: [
        "itemList"
    ]
}
</script>

<style scoped>
.cart_item_container{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid black;
}
.cart_item_container:nth-last-of-type(1){
    border: none;
    margin-bottom: 20px;
}
img.item_image{
    margin-right: 10px;
    width: 100px;
    height: 100px;
}
p.product_name{
    font-size: 12px;
    font-weight: 200;
    overflow: hidden;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
p.product_price{
    font-size: 16px;

}
div.item{
    display: flex;
}



</style>