<template>
    <div class="container">
        <AnoneItem v-for="item in itemList" :key="item" :item="item"></AnoneItem>
    </div>
</template>

<script>
import AnoneItem from "@/components/AnoneItem.vue";
export default {
    components: {
        AnoneItem
    },
    props: [
        "itemList"
    ]
}
</script>

<style scoped>
    @media screen and (min-width: 960px) {
        .container > div{
            width: calc(25% - 2px) !important;
        }
    }
    .container{
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
    }
    .container > div{
        width: calc(50% - 1px);
    }
</style>