<template>
    <Button>{{ text }}</Button>
</template>

<script>
    export default {
        props: [
            "text"
        ]
    }
</script>

<style scoped>
    button{
        width: 100%;
        text-align: center;
        background-color: #BDA6CF;
        color: #fff;
        border-radius: 8px;
        padding: 12px 24px;
    }
</style>