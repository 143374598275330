import { reactive, readonly } from 'vue';

export const state = () => {
  // 管理したいステートを定義
  const state = reactive({
    cart: [],
  });

  // TODO追加
  const addCart = (item) => {
    state.cart.push(item)
  };

  // TODOを削除
  const removeCart = (id) => {
    state.cart = state.cart.filter(cart => cart.id !== id)
  };

  return {
    state: readonly(state),
    addCart,
    removeCart,
  }
};

// provideメソッドに指定するInjectionKeyを指定
export const stateKeys = Symbol('state');