<template>
    <form method="get" action="#" class="search_container">
        <input type="text" size="25" placeholder="キーワード検索">
        <input type="submit" value="検索">
    </form>
    <h2 id="result_text">検索結果</h2>
</template>

<script>

</script>

<style>
.search_container{
  width: 100%;
  margin:0 auto;
  margin-top: 20px;
  position: relative;
  box-sizing: border-box;
  border: 2px solid #3879D9;
  padding: 3px 10px;
  border-radius: 3px;
  height: 2.3em;
  width: 265px;
  overflow: hidden;
  border-radius: 10px;
}
.search_container input[type="text"]{
  border: none;
  height: 2.0em;
}
.search_container input[type="text"]:focus {
  outline: 0;
}
.search_container input[type="submit"]{
  text-align: center;
  cursor: pointer;
  font-family: FontAwesome;
  border: none;
  background: #BDA6CF;
  color: #fff;
  position: absolute;
  width: 3.5em;
  height: 3.0em;
  right:0px;
  top: -5px;
  outline : none;
}
#result_text{
  width: 85%;
  margin: 0 auto;
  margin-top: 32px;
  font-weight: 700;
}
</style>