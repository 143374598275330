<template>
    <div class="item">
        <router-link :to="/detail/ + item.id"><img class="item_image" :src="require('../assets/items/' + item.image)" alt=""></router-link>
        <p class="price">¥{{ item.price.toLocaleString() }}</p>
        <p class="name">{{ item.name }}</p>
    </div>
</template>

<script>
export default {
    props: [
        //item は商品情報のオブジェクトを受け取る
        "item"
    ]
}
</script>

<style scoped>
    .item{
        margin-bottom: 16px;
    }
    .item_image{
        width: 100%;
        background-color: rgb(177, 177, 177);
    }
    .price{
        font-size: 1.2em;
        font-weight: 600;
    }
    .name, .price{
        padding: 0 6px;
    }
</style>