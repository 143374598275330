<template>
    <BasicLayout>
        <img :src="require('../assets/items/' + items[$route.params.id].image)" alt="" class="item_image">
        <div class="content">
            <h2 class="item_name">{{items[$route.params.id].name}}</h2>
            <p class="item_price">¥{{items[$route.params.id].price.toLocaleString()}}</p>
            <router-link to="/cart"><AnoneButton text="ショッピングバッグに追加" class="mb"/></router-link >
            <div class="description_wrapper">
                <h3>アイテムの説明</h3>
                <p class="item_desc">{{items[$route.params.id].desc}}</p>
            </div>
        </div>
    </BasicLayout>
</template>

<script>
    import BasicLayout from "@/layouts/BasicLayout.vue";
    import items from "@/Items";
    import AnoneButton from "@/components/AnoneButton.vue";

    export default {
        components: {
            BasicLayout,
            AnoneButton
        },
        props:[
            "item"
        ],
        data: function() {
            return {
                items: items
            }
        }
    };
</script>

<style scoped>
 @media screen and (min-width: 800px) {
    .item_image{
        display: block;
        width: 80vw !important;
        margin: 0 auto;
    }
    .content{
        padding: 24px !important;
    }
}
.item_image{
    width: 100%;
    background-color: rgb(140, 140, 140);
}
.content{
    padding: 8px;
}
.item_name{
    margin: 12px 0;
}
.item_price{
    font-size: 1.1rem;
    font-weight: 600;
    margin: 12px 0;
}
.mb{
    margin-bottom: 20px;
}
.description_wrapper{
    border-top: 1px solid rgb(168, 168, 168);
    padding: 8px 0;
}

.description_wrapper > h3{
    text-align: center;
    font-weight: 600;
    padding: 8px;
}
.item_desc{
    white-space: pre-wrap;
}
</style>