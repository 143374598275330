<template>
    <BasicLayout>
        <BannerSlider/>
        <ContentSection class="first">
            <AnoneTitle class="title"><h2>初心者におすすめ！ランキング</h2></AnoneTitle>
            <ItemContainer :itemList="items"></ItemContainer>
            <router-link to="/ItemList"><AnoneButton class="mt" text="ランキングを見にいく" /></router-link>
        </ContentSection>
        <ContentSection bgc="#EDEDED">
            <AnoneTitle class="title"><h2>新着アイテム</h2></AnoneTitle>
            <ItemContainer :itemList="items"></ItemContainer>
            <router-link to="/ItemList"><AnoneButton class="mt" text="新着アイテム一覧" /></router-link>
        </ContentSection>
    </BasicLayout>
</template>

<script>
    import BasicLayout from "@/layouts/BasicLayout.vue";
    import BannerSlider from '@/components/BannerSlider.vue';
    import AnoneTitle from "@/components/AnoneTitle.vue";
    import ContentSection from "@/components/ContentSection.vue";
    import AnoneButton from "@/components/AnoneButton.vue";
    import ItemContainer from "@/components/ItemContainer.vue";
    import items from "@/Items";
    export default {
        components: {
        BannerSlider,
        BasicLayout,
        AnoneTitle,
        ContentSection,
        AnoneButton,
        ItemContainer
    },
    data: function() {
        return {
            items: items
        }
    }
    };
</script>

<style scoped>
.first{
    margin-top: 0;
}
.mt{
    margin-top: 20px;
}
</style>