<template>
    <div class="menu" v-if="isOpen">
        <div class="menu_list">
            <router-link to="/">TOP</router-link>
            <router-link to="/">anoneについて</router-link>
            <router-link to="/ItemList">商品一覧</router-link>
            <router-link to="/">おすすめセットアップ</router-link>
            <router-link to="/">スタートアップ診断</router-link>
            <router-link to="/">マイページ</router-link>
        </div>
        <div class="help">
            <router-link to="/">お問い合わせ</router-link>
            <router-link to="/">ご利用ガイド</router-link>
        </div>
        <CloseIcon class="close" :size="48" v-on:click="closeFnc"/>
    </div>
</template>

<script>
    import CloseIcon from 'vue-material-design-icons/Close.vue';
    export default {
        components: {
            CloseIcon,
        },
        props: [
            "isOpen",
            "closeFnc"
        ],
    }
</script>

<style scoped>
    .menu{
        position: fixed;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: #BDA6CF;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30% 0;
        font-size: 1.1rem;
        font-weight: 400;
    }
    .menu_list{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-bottom: 100px;
    }
    .menu_list > a{
        text-align: center;
    }
    .help{
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }
    .close{
        position: fixed;
        bottom: 20px;
        right: 20px;
    }
</style>