<template>
    <section :style="bg">
        <slot></slot>
    </section>
</template>

<script>
export default {
    props: {
        bgc:{
            required: false,
            default: "#fff",
        }
    },
    computed: {
        // CSS変数を定義して、CSS側に渡したいVueコンポーネントの値を指定する
        // ※今回はpropsで受け取った値
        bg() {
            return {
                'background-color' : this.bgc,
            }
        }
    }
}
</script>

<style scoped>
    section{
        padding: 48px 24px ;
        margin-top: 64px;
    }
</style>