<template>
  <Carousel :settings=settings :breakpoints='brakepoints'>
    <Slide v-for="slide in photoGallery" :key="slide">
      <div class="carousel__item">
      <img class="sliderPhoto" v-bind:src="slide.photo"/>
      </div>
    </Slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </Carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { ref } from 'vue'
export default {


  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  prop:{
    itemsToShow: 1,
    itemsToScroll: 0,
    wrapAround: false,

  },
  setup() {
    const photoGallery = ref([
      {photo:require('@/assets/banner_1.png')},
      {photo:require('@/assets/banner_2.png')},
      {photo:require('@/assets/banner_3.png')},
    ]);
    const settings = {
      itemsToShow: 1,
    };
    const brakepoints = {
      960: {
        itemsToShow: 2.5,
      }
    };
    return {
      settings:settings,
      brakepoints:brakepoints,
      photoGallery:photoGallery
    }
  }
}
</script>

<style scoped>
img{
  width: 100%
}
.carousel__item {
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
img {
  object-fit: cover;
}

</style>