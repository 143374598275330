<template>
    <section>
        <AnoneHeader />
        <section id="content">
            <h1><router-link to="/"><img src='../assets/anoneC.png' alt="logo" class="logo"></router-link></h1>
            <slot></slot>
        </section>
    </section>
</template>

<script>
/* eslint-disable */
    import AnoneHeader from "@/components/AnoneHeader.vue";
    export default {
        components: { AnoneHeader },
        created() {
            window.ucOpen = this.open;
        },
        // 状態
        data() {
            return {
                isVisible: false
            }
        },
        // アクション
        methods: {
            open(){
                this.isVisible = true
            },
            close(){
                this.isVisible = false
            }
        }
    };
</script>

<style scoped>

    #content{
        min-height: 100vh;
    }
    h1{
        text-align: center;
        padding: 12px;
    }
    .logo{
        width: 20%;
        margin: 0 auto;
    }
</style>