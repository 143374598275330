const items = {
    1:{
        id: 1,
        name: "カットオフニットフーディー",
        price: 7800,
        image: "1.webp",
        desc: `バックルデザインやシルバージップデザインがポイントのショートブラウス

【デザイン】
フロントジップデザインにフリルでDearMyLoveらしさのあるかわいいショートブラウスです。
袖口のスクエアバックルに合皮ベルトがポイント♪
かわいさだけでなく、シルバーの配色デザインでかっこよさもあり、
ショート丈なので色々な着こなし次第で雰囲気が変わるアイテムです。

【素材】
しっかりとした素材になっています。
フロントジップファスナーで着脱可能。

【コーディネート】
ジャンスカやリブキャミソールとの相性抜群です。
ショート丈なのでオシャレに着こなして頂けます♪
お好みのスタイルでコーデしてみてください。`
    },
    2:{
        id: 2,
        name: "探偵ネクタイワンピース",
        price: 8990,
        image: "2.webp",
        desc: `かわいいデザインポイントたっぷりの探偵ワンピが今年も登場♪

【デザイン】
ふわっと広がるシルエットに身頃のデザインポイントたっぷりなワンピースです。
身頃のシルバーボタンがかわいく、探偵さんのようなデザインになっています。
ウエストもベルトできゅっと調節して頂けるのでラインを綺麗に見せてくれます。
丈は選べる2丈になっています。お好みでお選びください。
チュニック丈はセットアップやチラ見せショートパンツの着用をオススメ致します。
襟元のネクタイは取り外しが可能なので、お好みでリボンに付け替えてもかわいいです♪
※別売りのケープと合わせて着たい場合はピンクAをお選びください。
ピンクBはケープと違う柄でのお作りになっています。

【素材】
しっかりとした素材になっています。
裏地、脇ポケット付きです。後ろファスナーで着脱仕様。

【コーディネート】
お揃いの生地とデザインを使用したケープとのコーデがおすすめです！
ケープを着用して頂くとより探偵さんのようになれます♪
参戦服や双子コーデで目立つこと間違いなし♪
チュニック丈はお手持ちのセットアップシリーズのショートパンツと
着用して頂くとセットアップ風に着て頂けます♪`
    },
    3:{
        id:3,
        name: "ハートorレースショートソックス",
        price: 790,
        image: "3.webp",
        desc: `大人気のレースソックスのご紹介♪

【デザイン】
レースの柄は、ハートの縦柄と、総レース柄、お花柄で選べます。ぎゅっと伸ばすとハイソックス辺りまで伸ばして履くことも可能ですが、ショートソックスとしてお使いいただくのがおすすめ。

【素材】
伸縮性のある素材です。繊細な素材ですので、ひっかけなどにご注意ください。

【コーディネート】
履くだけで、足元までこてこてコーデになるソックスです♪ちょっとコーデがさみしい・・・？というときに履いていただくとまとまった可愛いコーデになること間違いなし♪

【衣装提供】
「JAPAN FASHION FESTA 2022」にて着用していただきました。
「2022 Junior.Earhu.Japan SAITAMA」にて着用していただきました。`
    },
    4:{
        id:4,
        name: "ハーネスデザイン地雷ジャージ",
        price: 8240,
        image: "4.webp",
        desc: `ハーネスベルトデザインポイントのオリジナルデザインジャージが登場！

【デザイン】

ハーネスベルトを付けたようなデザインがポイントのジャージが登場♪

丈感やサイズ感もゆったりと着用して頂けます。

さらりとした素材感で着心地もよく、さらっと羽織って頂けます。

ハーネスベルト部分にはポイントでシルバーのハトメやバックルがあります。

ファスナーはジャージデザインにこだわり、ビスロンファスナーを使用。

カラーも着用しやすいカラー展開になっています。

厚底パンプスやブーツなどあわせて地雷系コーデを楽しんでください♪

お揃いの生地でのレッグウォーマーも展開しています。

【素材】

さらりと着用しやすいジャージに使用した生地になっています。

しっかりとした素材になっています。

【コーディネート】

地雷系コーデにぴったりのアイテムです。

お揃いのカラーのレッグウォーマーと合わせて頂くとより

ジャージコーデが完成致します♪

ニーハイやショートソックスにスニーカーでも相性ぴったり♪

DearMyLoveオリジナルのかわいいジャージをこの機会にいかがでしょうか？`
    },
    5:{
        id: 5,
        name: "地雷系ファッション 韓国の女子学生風 清楚なブラウス＋ワンピース セット",
        price: 7800,
        image: "5.webp",
        desc: `バックルデザインやシルバージップデザインがポイントのショートブラウス

【デザイン】
フロントジップデザインにフリルでDearMyLoveらしさのあるかわいいショートブラウスです。
袖口のスクエアバックルに合皮ベルトがポイント♪
かわいさだけでなく、シルバーの配色デザインでかっこよさもあり、
ショート丈なので色々な着こなし次第で雰囲気が変わるアイテムです。

【素材】
しっかりとした素材になっています。
フロントジップファスナーで着脱可能。

【コーディネート】
ジャンスカやリブキャミソールとの相性抜群です。
ショート丈なのでオシャレに着こなして頂けます♪
お好みのスタイルでコーデしてみてください。`
    },
    6:{
        id: 6,
        name: "探偵ネクタイワンピース",
        price: 8990,
        image: "6.webp",
        desc: `かわいいデザインポイントたっぷりの探偵ワンピが今年も登場♪

【デザイン】
ふわっと広がるシルエットに身頃のデザインポイントたっぷりなワンピースです。
身頃のシルバーボタンがかわいく、探偵さんのようなデザインになっています。
ウエストもベルトできゅっと調節して頂けるのでラインを綺麗に見せてくれます。
丈は選べる2丈になっています。お好みでお選びください。
チュニック丈はセットアップやチラ見せショートパンツの着用をオススメ致します。
襟元のネクタイは取り外しが可能なので、お好みでリボンに付け替えてもかわいいです♪
※別売りのケープと合わせて着たい場合はピンクAをお選びください。
ピンクBはケープと違う柄でのお作りになっています。

【素材】
しっかりとした素材になっています。
裏地、脇ポケット付きです。後ろファスナーで着脱仕様。

【コーディネート】
お揃いの生地とデザインを使用したケープとのコーデがおすすめです！
ケープを着用して頂くとより探偵さんのようになれます♪
参戦服や双子コーデで目立つこと間違いなし♪
チュニック丈はお手持ちのセットアップシリーズのショートパンツと
着用して頂くとセットアップ風に着て頂けます♪`
    },
    7:{
        id:7,
        name: "ハートorレースショートソックス",
        price: 790,
        image: "7.webp",
        desc: `大人気のレースソックスのご紹介♪

【デザイン】
レースの柄は、ハートの縦柄と、総レース柄、お花柄で選べます。ぎゅっと伸ばすとハイソックス辺りまで伸ばして履くことも可能ですが、ショートソックスとしてお使いいただくのがおすすめ。

【素材】
伸縮性のある素材です。繊細な素材ですので、ひっかけなどにご注意ください。

【コーディネート】
履くだけで、足元までこてこてコーデになるソックスです♪ちょっとコーデがさみしい・・・？というときに履いていただくとまとまった可愛いコーデになること間違いなし♪

【衣装提供】
「JAPAN FASHION FESTA 2022」にて着用していただきました。
「2022 Junior.Earhu.Japan SAITAMA」にて着用していただきました。`
    },
    8:{
        id:8,
        name: "ハーネスデザイン地雷ジャージ",
        price: 8240,
        image: "8.webp",
        desc: `ハーネスベルトデザインポイントのオリジナルデザインジャージが登場！

【デザイン】

ハーネスベルトを付けたようなデザインがポイントのジャージが登場♪

丈感やサイズ感もゆったりと着用して頂けます。

さらりとした素材感で着心地もよく、さらっと羽織って頂けます。

ハーネスベルト部分にはポイントでシルバーのハトメやバックルがあります。

ファスナーはジャージデザインにこだわり、ビスロンファスナーを使用。

カラーも着用しやすいカラー展開になっています。

厚底パンプスやブーツなどあわせて地雷系コーデを楽しんでください♪

お揃いの生地でのレッグウォーマーも展開しています。

【素材】

さらりと着用しやすいジャージに使用した生地になっています。

しっかりとした素材になっています。

【コーディネート】

地雷系コーデにぴったりのアイテムです。

お揃いのカラーのレッグウォーマーと合わせて頂くとより

ジャージコーデが完成致します♪

ニーハイやショートソックスにスニーカーでも相性ぴったり♪

DearMyLoveオリジナルのかわいいジャージをこの機会にいかがでしょうか？`
    }
};
export default items;