import { createRouter, createWebHistory } from 'vue-router'
import AnoneTop from "@/pages/AnoneTop.vue"
import AnoneCart from "@/pages/AnoneCart.vue"
import ItemList from "@/pages/ItemList.vue"
import ItemDetail from "@/pages/ItemDetail.vue"

const routes = [
  {
    path: '/',
    name: 'top',
    component: AnoneTop
  },
  {
    path: '/cart',
    name: 'cart',
    component: AnoneCart
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: ItemDetail
  },
  {
    path: '/ItemList',
    name: 'ItemList',
    component: ItemList
  }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    scrollBehavior () {
      return { top:0 }
    }
})

export default router