



<template>
    <BasicLayout>
        <h1 class="cart_item">カート内の商品</h1>
        <!-- カートアイテムコンポネント -->
        <AnoneCartItemContainer  :itemList="items"></AnoneCartItemContainer>

        <div class="total_amount_box">
            <p class="total_amount">小計 :</p><p class="total_amount_money">18800<span class="yen">円</span></p>
        </div>
        <AnoneButton class="mt mb" text="購入画面へ" />
    </BasicLayout>
</template>

<script>
    import BasicLayout from "@/layouts/BasicLayout.vue";
    import AnoneButton from "@/components/AnoneButton.vue";
    import AnoneCartItemContainer from "@/components/AnoneCartItemContainer.vue";
    import items from "@/Items";
    export default {
        components: {
        BasicLayout,
        AnoneButton,
        AnoneCartItemContainer
    },
    data: function() {
        return {
            items: items
        }
    }
    };
</script>

<style scoped>
h1.cart_item{
    font-size: 20px;
    font-weight: bold;
    padding: 12px;
    padding-left: 0px;
    margin: 0 auto;
    width: 80%;
}
div.total_amount_box{
    border: 1px black solid;
    border-radius: 8px;
    width: 80%;
    margin: 0 auto;
    height: 72px;
}
p.total_amount{
    display: inline-block;
    line-height: 72px;
    font-weight: bold;
    margin-left: 20px;
}
p.total_amount_money{
    display: inline-block;
    font-size: 24px;
    width: 230px;
    text-align: right;
}
p.total_amount_money span.yen{
    font-size: 16px;
    color: red;
    font-weight: bold;
    padding-left: 10px;
}
.mt{
    display: block;
    margin: 0 auto;
    margin-top: 16px;
    width: 80%;
}

.mb{
    margin-bottom: 30px;
}

</style>